<template>
  <moe-page title="日志管理">
    <!-- 筛选信息 -->
    <moe-inquire @search="journalSearch">
      <el-form-item label="操作人">
        <el-input v-model.trim="journalParams.userName" placeholder="输入操作人" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="操作时间">
        <el-date-picker placement="bottom-start" v-model="dateTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="(datetime) => ([journalParams.minTime, journalParams.maxTime] = datetime || ['', ''])" />
      </el-form-item>
    </moe-inquire>

    <!-- 日志信息 -->
    <moe-table ref="journalTable" url="/log/pageList" :params="journalParams">
      <el-table-column prop="userName" label="操作人" min-width="130" />
      <el-table-column prop="content" label="操作内容" min-width="130" />
      <el-table-column prop="createTime" label="操作时间" min-width="130">
        <template slot-scope="{ row }">{{ $moe_time.getTimeStamp(row.createTime) }}</template>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'JournalList',
  data() {
    //日志筛选信息
    let journalParams = {
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
      userName: '', //操作人
      minTime: '', //开始时间
      maxTime: '', //结束时间
    };

    return {
      journalParams, //日志筛选信息
      dateTime: [],
    };
  },
  methods: {
    /**
     * 日志搜索
     **/
    journalSearch(isSearch) {
      if (!isSearch) {
        this.journalParams = {
          pageNum: 1, //当前页
          pageSize: 10, //每页条数
          userName: '', //操作人
          minTime: '', //开始时间
          maxTime: '', //结束时间
        };

        this.dateTime = [];
      }

      //刷新日志表格
      this.$refs.journalTable.searchData();
    },
  },
};
</script>